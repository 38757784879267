





























































































import Vue from 'vue'
import SelectCoupon from '@/components/select/select-coupon.vue'
import { setModelField, getOssUrl } from '@/api/common'
import { useTable } from '@/components/table/table-hook'
import { getIntegralMallGoodList, editIntegralMallGood, delIntegralMallGood } from '@/api/integral-mall-goods'
import { cloneDeep } from 'lodash'
import ModelFieldEditor from '@/components/form/model-field-editor.vue'

export default Vue.extend({
	components: { SelectCoupon, ModelFieldEditor },
	data() {
		return {
			baseUrl: '',
			oldBaseUrl: '',
			editIntegralMallGood,
			...useTable({
				name: '积分商城商品',
				formdata: {
					id: 0,
					images: [],
					name: '',
					desc: '',
					detail_images: [],
					original_price: 0,
					price: 0,
				},
				query: {
					sort_key: 'sort',
					sort_type: 'desc',
				},
				getData: (query) => {
					let n_query = cloneDeep(query)
					if (n_query['datetimes']) {
						n_query['start_time'] = n_query['datetimes'][0]
						n_query['end_time'] = n_query['datetimes'][1]
					}
					console.log(n_query)
					return getIntegralMallGoodList(n_query).then((res) => res.data)
				},
				delData: (row) => delIntegralMallGood({ id: row.id }),
			}),
		}
	},
	methods: {
		getOssUrl() {
			getOssUrl().then((res) => {
				this.baseUrl = res.data.domain
				this.oldBaseUrl = res.data.old_domain
			})
		},
		onSubmit(row) {
			let param = row
			let base_url_length = this.baseUrl.split('').length
			if (param.images != null) {
				let coverImage = [] as any
				param.images.forEach((el) => {
					if (el.indexOf(this.baseUrl) !== -1) {
						el = el.slice(base_url_length)
						if (el.indexOf(this.baseUrl) !== -1) {
							el = el.slice(base_url_length)
						}
					}
					coverImage.push(el)
				})
				param.images = JSON.stringify(coverImage) // 多图
			}
			if (param.detail_images != null) {
				let detailImage = [] as any
				param.detail_images.forEach((el) => {
					if (el.indexOf(this.baseUrl) !== -1) {
						el = el.slice(base_url_length)
						if (el.indexOf(this.baseUrl) !== -1) {
							el = el.slice(base_url_length)
						}
					}
					detailImage.push(el)
				})
				param.detail_images = JSON.stringify(detailImage) // 多图
			}
			// param.images = JSON.stringify(param.images) //轮播图
			// param.detail_images = JSON.stringify(param.detail_images) //详情图
			return editIntegralMallGood(param).then(() => this.$message.success('保存成功') && this.tableGetData())
		},
		edit(row) {
			const params = cloneDeep(row)
			params.images = row.images ? JSON.parse(row.images) : []
			params.detail_images = row.detail_images ? JSON.parse(row.detail_images) : []
			;(this.$refs.formDialogRef as any).open(params)
		},
		changeTj(row) {
			setModelField({
				model: 'IntegralMallGood',
				id: row.id,
				field: 'is_recommend',
				value: row.is_recommend == 1 ? 0 : 1,
			}).then((res) => {
				this.$message.success('操作成功！')
				if (row.is_recommend == 1) {
					row.is_recommend = 0
				} else {
					row.is_recommend = 1
				}
			})
		},
	},
	created() {
		this.getOssUrl()
		this.tableGetData()
	},
})
