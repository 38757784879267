




























































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { cloneDeep } from 'lodash'
import { getBalanceRefundList, auditBalanceRefund } from '@/api/balance-refund'
import SelectBalanceType from '@/components/select/select-balance-type.vue'
export default Vue.extend({
	components: {
		SelectBalanceType,
	},
	data() {
		return {
			...useTable({
				name: '退款',
				formdata: {
					/** ID */
					id: 0,
					/** 昵称 */
					name: '',
				},
				getData: (query) => {
					let n_query = cloneDeep(query)
					if (n_query['datetimes']) {
						n_query['start_time'] = n_query['datetimes'][0]
						n_query['end_time'] = n_query['datetimes'][1]
					}
					return getBalanceRefundList(n_query).then((res) => res.data)
				},
			}),
			options: [
				{
					audit_state: 0,
					label: '已提交',
				},
				{
					audit_state: 1,
					label: '已打款',
					disabled: true,
				},
				{
					audit_state: 2,
					label: '未通过',
					disabled: true,
				},
			],
		}
	},
	created() {
		this.tableGetData()
	},
	methods: {
		//修改状态向后台发送请求
		select(id, value) {
			console.log('====================================')
			console.log(value)
			console.log('====================================')
			this.$confirm('确认修改此状态吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.$message({
						type: 'success',
						message: '修改状态成功!',
					})
					setTimeout(() => {
						auditBalanceRefund({ id: id, audit_state: value }).then((res) => {
							console.log(res)
						})
					}, 500)
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消修改',
					})
				})
		},
	},
})
