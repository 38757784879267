import request, { ResponsePageableData } from '@/libs/request'
/** 卡类型*/
export const getCardList = (data?) => request<ResponsePageableData>({ url: 'adminapi/RechargeCard/getCardList', data })

/** 卡类型*/
export const cardState = (data?) => request<ResponsePageableData>({ url: 'adminapi/RechargeCard/cardState', data })

// 生成卡列表
export const getRechangeCardlist = (data?) => request<ResponsePageableData>({ url: 'adminapi/RechargeCard/getRechargeCardlist', data })

/** 创建卡号*/
export const rechargeCard = (data?) => request<ResponsePageableData>({ url: 'adminapi/RechargeCard/addRechargeCard', data })

/** 充值卡订单列表 */
export const getRecharge = (data?) => request<ResponsePageableData>({ url: '/adminapi/RechargeCard/getRechargeCardOrderList', data })

/** 发卡 */
export const GiveCards = (data?) => request<ResponsePageableData>({ url: '/adminapi/RechargeCard/GiveCards', data })

/** 确认制卡 */
export const fabrication = (data?) => request<ResponsePageableData>({ url: '/adminapi/RechargeCard/fabrication', data })

/** 制卡完成 */
export const receive = (data?) => request<ResponsePageableData>({ url: '/adminapi/RechargeCard/receive', data })

/** 发卡 */
export const distribute = (data?) => request<ResponsePageableData>({ url: '/adminapi/RechargeCard/distribute', data })

/** 删除卡 */
export const delCard = (data?) => request<ResponsePageableData>({ url: '/adminapi/RechargeCard/delCard', data })
