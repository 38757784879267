



















































































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { getBalanceGoodList } from '@/api/hotel-label'
import SelectVipLv from '@/components/select/select-vip-lv.vue'
import SelectBalanceType from '@/components/select/select-integral-type.vue'
import { export_json_to_excel } from '@/vendor/Export2Excel'
import { cloneDeep } from 'lodash'
export default Vue.extend({
	components: { SelectVipLv, SelectBalanceType },
	data() {
		return {
			dialogVisible: false,
			export_data: [] as any,
			is_export: [] as any,
			...useTable({
				name: '',
				formdata: {
					/** ID */
					id: 0,
					/** 名称 */
					name: '',
				},
				getData: (query) => {
					let n_query = cloneDeep(query)
					if (n_query['datetimes']) {
						n_query['start_time'] = n_query['datetimes'][0]
						n_query['end_time'] = n_query['datetimes'][1]
					} else if (n_query['datetimes'] == null) {
						n_query['datetimes'] = []
						n_query['start_time'] = ''
						n_query['end_time'] = ''
						n_query['is_export'] = 0
					}

					console.log(n_query)

					return getBalanceGoodList(n_query).then((res) => res.data)
				},
			}),
		}
	},
	created() {
		this.tableGetData()
	},
	methods: {
		inportexcel() {
			this.dialogVisible = true
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map((v) => filterVal.map((j) => v[j]))
		},
		que() {
			setTimeout(() => {
				this.table_query.is_export = 0
			}, 500)
			this.dialogVisible = false
			this.table_query.is_export = 1
			if (this.table_query['datetimes']) {
				this.table_query['start_time'] = this.table_query['datetimes'][0]
				this.table_query['end_time'] = this.table_query['datetimes'][1]
			} else if (this.table_query['datetimes']) {
				this.table_query['start_time'] = ''
				this.table_query['end_time'] = ''
			}
			getBalanceGoodList(this.table_query).then((res) => {
				this.export_data = res.data.rows
				this.export_data.push({
					id: '合计',
					out_trade_no: '',
					pay_fee: res.data.count.sum_pay_fee,
					nick_name: '',
					mobile: '',
					state: '-',
					create_time: '',
				})
				this.export_data.forEach((it) => {
					if (it.hotel) {
						it.hotel_name = it.hotel.name
					}
					if (it.user) {
						it.nick_name = it.user.nick_name
						it.mobile = it.user.mobile
					}
					it.total_fee = it.total_fee / 100
					it.discount_fee = it.discount_fee / 100
					it.no_discount_fee = it.no_discount_fee / 100
					it.discount = it.discount / 100
					it.balance = it.balance / 100
					it.coupon_price = it.coupon_price / 100
					it.pay_fee = it.pay_fee / 100
					if (it.user) {
						if (it.user.vip_key == 0) {
							it.vip_key = '白银会员'
						} else if (it.user.vip_key == 1) {
							it.vip_key = '金卡会员'
						} else if (it.user.vip_key == 2) {
							it.vip_key = '铂金会员'
						} else if (it.user.vip_key == 3) {
							it.vip_key = '钻石会员'
						} else if (it.user.vip_key == 4) {
							it.vip_key = '永久钻石会员'
						} else {
							it.vip_key = '-'
						}
					} else {
						it.vip_key = '-'
					}
					if (it.state == 0) {
						it.state = '未支付'
					} else if (it.state == 1) {
						it.state = '已支付'
					} else {
						it.state = '-'
					}
				})
				//兼容ie10哦！
				require.ensure([], () => {
					const tHeader = ['序号', '订单编号', '消费金额', '客户名称', '客户电话', '客户等级', '支付状态', '创建时间'] //将对应的属性名转换成中文
					// const tHeader = [];
					const filterVal = ['id', 'out_trade_no', 'pay_fee', 'nick_name', 'mobile', 'vip_key', 'state', 'create_time'] //table表格中对应的属性名
					const list = this.export_data
					const data = this.formatJson(filterVal, list)
					this.is_export = 0
					export_json_to_excel(tHeader, data, '余额充值订单')
				})
			})
		},
		decodeUnicode(str) {
			str = str.replace(/\\/g, '%')
			// console.log(str, 'str')
			return unescape(str)
		},
	},
})
