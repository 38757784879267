import request, { ResponsePageableData } from '@/libs/request'

/** 积分商城商品列表 */
export const getIntegralMallGoodList = (data?) => request<ResponsePageableData>({ url: '/adminapi/IntegralMallGood/getIntegralMallGoodList', data })

/** 积分商城商品新增/编辑 */
export const editIntegralMallGood = (data?) => request<ResponsePageableData>({ url: '/adminapi/IntegralMallGood/editIntegralMallGood', data })

/** 积分商城商品删除 */
export const delIntegralMallGood = (data?) => request<ResponsePageableData>({ url: '/adminapi/IntegralMallGood/delIntegralMallGood', data })
